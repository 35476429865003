@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");

.shop_page {
  position: relative;
  width: 100%;
  height: auto;
  background: url('/public/imagesfurnis/background1.jpg') ;
  background-size:cover;
  background-repeat: no-repeat;
  font-family: "Space Grotesk", sans-serif;
}

.shop_page .all_shop .file_search_box {
  position: relative;
  width: 100%;
  height: 33px;
  margin-top: 50px;
  font-family: "Space Grotesk", sans-serif;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.shop_page .all_shop .file_box {
  position: relative;
  width: 420px;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.shop_page .all_shop .file_box a {
  position: relative;
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 25px;
  color: #ffffff;
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.shop_page .all_shop .file_box a::before,
.file_box a ::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
}
.shop_page .all_shop .file_box a::before {
  background-color: #d7b686;
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.76, 6, 6.24, 1);
}

.shop_page .all_shop .file_box a::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.76, 6, 6.24, 1);
  color: #d7b686;
}

.shop_page .all_shop .file_box a:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}

.shop_page .all_shop .file_box a:hover::after {
  transform: translate3d(0, 0, 0);
}

.shop_page .all_shop .file_box a:hover span {
  transform: translate3d(-200%, 0, 0);
}

.shop_page .all_shop .file_box a {
  text-decoration: none;
  color: #021641;
  font-weight: 700;
  vertical-align: top;
}

.shop_page .all_shop .search_big_box {
  position: relative;
  font-family: "Space Grotesk", sans-serif;

  width: 360px;
  height: 33px;
  display: flex;
  align-items: center;
  background: #fffcfc;
  border: 1px solid #fff none;
  box-shadow: 0px 4px 4px rgba(240, 204, 204, 0.25);
  border-radius: 18.5px;
  box-sizing: border-box;
}
.shop_page .all_shop .search_form {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Space Grotesk", sans-serif;
}
.shop_page .all_shop .searchInput {
  position: relative;
  width: 245px;
  height: 29px;
  margin-left: 10px;
  display: flex;
  border: none;
  border-radius: 10px 0px 0px 10px;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  line-height: 26px;
  color: #4f547b;
}
.shop_page .all_shop .button_search {
  position: relative;
  width: 100px;
  height: 33px;
  font-family: "Space Grotesk", sans-serif;

  display: flex;

  border-radius: 18.5px;
  background-color: #0969a0;
}
.shop_page .all_shop .all_res_box {
  position: relative;
  width: 1305px;
  height: auto;
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.shop_page .all_shop .restaurant_box {
  margin: 13px;
  padding: 5px;
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 8px;
}

.shop_page .all_shop .restaurant_img {
  width: 200px;
  height: 330px;

  border-radius: 5px;
  background-size: cover;
}

.shop_page .all_shop .restaurant_desc {
  width: 280px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.shop_page .all_shop .restaurant_title_text {
  font-family: "Space Grotesk", sans-serif;

  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-transform: capitalize;
  color: #140342;
}

.shop_page .all_shop .restaurant_desc_text {
  margin-top: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #4f547b;
}
.shop_page .all_shop .bottom_box {
  position: relative;
  width: 100%;
  height: 168px;
  margin-top: 100px;
  font-family: "Space Grotesk", sans-serif;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/* Chosen Shop started*/

.chosen_shop {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: rgb(247, 251, 255);
}

.chosen_shop .avatar_big_box {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  position: relative;
  width: 100%;
}

.chosen_shop .top_text {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 43px;
  justify-content: space-between;
  margin-top: 80px;
  position: relative;
  width: 100%;
}

.chosen_shop .top_text p {
  color: #032946;
  font-family: "Space Grotesk", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 43px;
  margin-left: 380px;
  position: relative;
}

.chosen_shop .Single_search_big_box {
  position: relative;
  width: 360px;
  height: 33px;
  display: flex;
  align-items: center;
  background: #fffcfc;
  border: 1px solid #fff;
  box-shadow: 0px 4px 4px rgba(213, 213, 213, 0.25);
  border-radius: 18.5px;
  box-sizing: border-box;
}
.chosen_shop .Single_search_form {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.chosen_shop .Single_searchInput {
  position: relative;
  width: 245px;
  height: 29px;
  margin-left: 10px;
  display: flex;
  border: none;
  border-radius: 10px 0px 0px 10px;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #4f547b;
  outline: none;
}
.chosen_shop .Single_button_search {
  position: relative;
  width: 100px;
  height: 33px;
  display: flex;

  border-radius: 18.5px;
  background-color: #69a2f8;
}

.chosen_shop .prev_btn {
  align-items: center;
  display: flex;
  height: 147px;
  justify-content: center;
  width: 10%;
  cursor: pointer;
}
.chosen_shop .swiper-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chosen_shop .shop_avatars_wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  height: 147px;
  width: 90%;
}

.chosen_shop .shop_avatars {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 108px;
  cursor: pointer;

}
.chosen_shop .shop_avatars img {
  border-radius: 50%;
  height: 108px;
  width: 108px;
  cursor: pointer;
}
.chosen_shop .shop_avatars span {
  color: #06266d;
  font-family: "Space Grotesk", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin-top: 14px;
}
.chosen_shop .next_btn {
  align-items: center;
  display: flex;
  height: 147px;
  justify-content: center;
  width: 10%;
}

.chosen_shop .prodes_filter_box {
  height: auto;
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 80px;
}

.shop_span{
  color: #021641;
  font-size: 15px;
}

.chosen_shop .prod_category_box {
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: 60px;
  width: 50px;
}
.chosen_shop .prod_category_main {
  display: flex;
  flex-direction: row;
  justify-content: end;
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.chosen_shop .prod_category_main button {
  min-width: auto;
  margin-right: 10px;
}

.chosen_shop .prod_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  height: auto;
  margin-left: 50px;
  margin-top: -90px;
  width: 100%;
  justify-content: center;
}
.chosen_shop .prod_box {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-right: 15px;
  margin-top: 60px;
  width: 273px;
  cursor: pointer;
}
.chosen_shop .prod_box .prod_img {
  background-size: cover;
  border-radius: 10px;
  height: 360px;
  position: relative;
  width: 273px;
}
.chosen_shop .prod_box .prod_img:hover .like_view_btn {
  opacity: 1;
  margin-top: 70px;
}
.chosen_shop .prod_box .prod_img:hover .view_btn {
  opacity: 1;
  margin-top: 70px;
}

.chosen_shop .like_view_btn {
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 40px;
  min-width: 40px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  top: 223px;
  transition: opacity 0.5s ease;
  width: 40px;
  z-index: 3;
  margin-top: 70px;

}
.chosen_shop .view_btn {
  align-items: center;
  background:rgb(128, 233, 247);
  border: 2px solid #faefde;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: 46px;
  left: 105px;
  line-height: 15px;
  opacity: 0;
  padding: 20px;
  position: absolute;
  top: 217px;
  transition: opacity 0.5s ease;
  width: 64px;
  z-index: 3;
  margin-top: 70px;

}
.chosen_shop .prod_box .prod_desc {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 90%;
}

.chosen_shop .prod_desc .prod_title_text {
  color: #032946;
  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-top: 18px;
}
.chosen_shop .prod_desc .prod_desc_text {
  align-items: center;
  color: #e3c08d;
  display: flex;
  flex-direction: row;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  line-height: 36px;
  margin-top: 15px;
}

.chosen_shop .category_title {
  color: #03296e;
  font-family: "Space Grotesk", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 43px;
}
.chosen_shop .about_right {
  display: flex;
  flex-direction: column;
  height: 480px;
  justify-content: space-between;
  margin-left: 20px;
  width: 50%;
}
.chosen_shop .about_right .about_right_img {
  background: url(https://i0.wp.com/educationplanetonline.com/wp-content/uploads/2022/03/Culinary-Schools-in-Europe-1.jpg);
  background-position: 50%;
  background-size: cover;
  border-radius: 0 50px 0 0;
  display: flex;
  height: 140px;
  left: 763px;
  top: 3653px;
  width: 160px;
}

.chosen_shop .about_right .about_right_desc {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 20px;
  padding-bottom: 5px;
}
.chosen_shop .about_right_desc span {
  color: #fff;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.chosen_shop .about_right_desc p {
  color: #d4d4d4;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-top: 10px;
}

/* Chosen Shop end*/


/* ChosenProduct start */

 .shop_page .chosen_product_page {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

background-color: #f3fdff;}

.restaurant_page .dish_container {
display: flex;
flex-direction: row;
justify-content: space-between;
padding-top: 60px;
padding-bottom: 60px;
}

.chosen_product_page .chosen_product_slider {
  width: 48%;
  height: 581px;
}

.chosen_product_page .dish_swiper {
  --swipper-navigation-color: #fff;
  --swipper-pagination-color: #fff;
  height: 1020px;
  width: 100%;
}

.chosen_product_page .chosen_product_info_container {
  padding: 30px 40px;
  width: 49%;
  height: 582px;
  display: flex;
  background: #f9ffff;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0 , 0.25),
  inset 0px 4px 4px rgba(0, 0, 0 , 0.25), inset 0px;
  border-radius: 10px;
  font-family: "Space Grotesk", sans-serif;

}

.chosen_product_page .chosen_product_info_container .chosen_product_info_box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.chosen_product_page .chosen_product_info_box .dish_txt {
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color:#000000;
  
}

.chosen_product_page .chosen_product_info_box .resto_name {
  margin-top: 16px;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 15px;
  color: #979797;
  margin-top: 30px;

} 

.chosen_product_page .chosen_product_info_box .rating_box {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  margin-top: 60px;

}
.chosen_product_page .rating_box .evaluation_box, .chosen_product_page .rating_box .star_box {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  width: auto;
}
.chosen_product_page .chosen_product_info_box .dish_desc_info {
  color: #000;
  font-family: "Space Grotesk", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 112px;
  line-height: 22px;
  margin-bottom: 30px;
  margin-top: 120px;
}
.chosen_product_page .chosen_product_info_box .dish_price_box {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 20px;
  width: 100%;
}
.chosen_product_page .dish_price_box span {
  color: #1678f8;
  font-family: "Space Grotesk", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.chosen_product_page .button_box {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  width: 100%;
}
.chosen_product_page .button_prod{
  width: 180px;
  background: #000000;
  margin-top: 30px;
}