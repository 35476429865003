@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");

.format.home_navbar {
  width: 100%;
  height: 760px;
  display: flex;
  /* background-color: aliceblue; */
  background-size: cover;
  font-family: "Space Grotesk", sans-serif;
justify-content: space-between;
}

.format_shop.home_navbar {
  width: 100%;
  height: 460px;
  display: flex;
  background: url("/public/imagesfurnis/otherheader.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0cm;
  justify-content: space-between;


  font-family: "Space Grotesk", sans-serif;
}

.format_other.home_navbar {
  width: 100%;
  height: 400px;
  display: flex;
  background: url("/public/imagesfurnis/otherheader.png");
  background-size: cover;
  background-position: 0cm;
  font-family: "Space Grotesk", sans-serif;
  justify-content: space-between;
 
}
.home_navbar .navbar_container {
  height: 100px;
  margin-top: 55px;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;


}
.home_navbar .navbar_links {
  display: flex;
justify-content: space-evenly;
flex-direction: row;
  width: 752px;
  height: 46px;
  

}

.home_navbar a {
  text-decoration: none;
}

.home_navbar .hover a {
  color: #021641;
  font-weight: 600;
  font-size: larger;
}

.home_navbar .underline {
  display: block;
  content: "";
  border-bottom: solid 1.5px rgb(6, 12, 107);
}

.home_navbar .hover:after {
  display: block;
  content: "";
  border-bottom: solid 2px rgb(16, 41, 109);
  transform: scaleX(0);
  transition: transform 250ms ease;
}

.home_navbar .hover:hover:after {
  transform: scaleX(1);
}

.hover{
  margin-right: 10px;
  
}
.mySwiper {
  width: 100%;
  height: 660px;
  
}
.slide1 {
  width: fit-content;
  background: url("/public/imagesfurnis/sofa.jpeg");
  background-size: cover;
  background-repeat: no-repeat;

}

.slide2 {
  background-size: cover;
  background-repeat: no-repeat;
  background: url("/public/imagesfurnis/slide2.jpeg");
}
.slide3 {
  width: 1120px;

  background: url("/public/imagesfurnis/slide3.jpeg");
}


.home_navbar span {
  color: rgb(255, 115, 0);
}

.header_button {
  background: linear-gradient(to bottom right, #ef4765, #ff9a5a);
  border: 0;
  border-radius: 12px;
  color: #030c3d;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  margin-top: 10px;
  margin-left: 60px;
  font-family: "Space Grotesk", sans-serif;
}

.header_button:not([disabled]):focus {
  box-shadow: 0 0 0.25rem rgba(241, 194, 194, 0.5),
    -0.125rem -0.125rem 1rem rgba(250, 171, 80, 0.5),
    0.125rem 0.125rem 1rem rgba(255, 154, 90, 0.5);
}

.header_button:not([disabled]):hover {
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5),
    -0.125rem -0.125rem 1rem rgba(239, 71, 101, 0.5),
    0.125rem 0.125rem 1rem rgba(255, 172, 48, 0.5);
}

#parent {
  white-space: nowrap;
  overflow: hidden;
  font-family: "Source Code Pro", monospace;
  font-size: 28px;
  width: 480px;
  text-align: left;
  margin-top: 120px;
  margin-left: 60px;
  font-size: 39px;
  font-weight: bold;
  color: #021641;
}

#border {
  border-bottom: solid 3px rgba(0, 255, 0, 0.75);
  position: absolute;
}

/* Animation */
#parent {
  animation: animated-text 2s steps(30, end) 1s 1 normal both;
}

#border {
  animation: animated-cursor 600ms steps(30, end) infinite;
}

/* text animation */

@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    width: 480px;
  }
}

/* cursor animations */

@keyframes animated-cursor {
  from {
    border-bottom-color: rgba(0, 255, 0, 0.75);
  }
  to {
    border-bottom-color: transparent;
  }
}


.home_navbar .center_header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  color: #021641;
}

.format_shop span{color: rgb(255, 255, 255);}


/* START BASKET */

.basket_frame {
  position: relative;
  padding: 10px 4px 0px 4px;
  width: 450px;
  height: auto;

  display: flex;
  flex-direction: column;
  background: #e9f7fd;
  border-radius: 16px;
}

.basket_frame .all_check_box {
  padding: 0px 6px;
  width: 100%;
  height: 35px;

  display: flex;
  flex-direction: row;
  align-items: center;
  background: #d9d9d9;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #f7e7e7;
}

.basket_frame .basket_info_box {
  position: relative;
  margin-top: 6px;
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.basket_info_box .product_img {
  width: 60px;
  height: 60px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  border-radius: 29px;
}

.basket_info_box .product_name {
  margin-left: 11px;
  width: 185px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.basket_info_box .product_price {
  width: 80px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #423c3c;
}

.basket_info_box
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6.5px 14px;
}

.basket_info_box .css-ece9u5 {
  min-width: 72px;
}

.basket_frame .cancel_btn {
  position: absolute;
  top: 33%;
  right: 5%;
  cursor: pointer;
}

.basket_frame .to_order_box {
  margin-top: 10px;
  width: 100%;
  height: 64px;

  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.to_order_box .price_text {
  margin-left: 27px;
  margin-right: 10px;
  width: 200px;
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.basket_frame .orders_wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.basket_frame .orders_main_wrapper {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  width: 100%;
  height: 260px;

  display: flex;
  flex-direction: column;
}

/* END BASKET */