.order_page {
  width: 100%;
  height: auto;
  background: url("/public/imagesfurnis/background1.jpg");

  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  font-family: "Space Grotesk", sans-serif;
}

.order_page .order_left {
  height: auto;
  width: 80%;
}
.order_page .order_left .order_nav_frame {
  border-bottom: 1px solid #272424;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  width: 100%;
  font-family: "Space Grotesk, sans-serif;";
}
.order_page .order_main_content {
  height: auto;
  width: 100%;
}
.order_page .order_main_content .order_main_box {
  background: #fff;
  border-radius: 2px;
  margin-bottom: 25px;
  max-height: 284px;
  position: relative;
  width: 100%;
}
.order_page .order_main_content .order_main_box .order_box_scroll {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  max-height: 203px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
}
.order_page .order_box_scroll .ordersName_price {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 47px;
  margin-left: 40px;
  margin-top: 5px;
  position: relative;
  width: 100%;
}
.order_page .order_box_scroll .ordersName_price .orderDishImg {
  background-size: cover;
  border-radius: 5px;
  height: 47px;
  position: relative;
  width: 50px;
}
.order_page .order_box_scroll .ordersName_price .titleDish {
  color: #000;
  font-family: "Space Grotesk", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  margin-left: 20px;
  position: relative;
  width: 260px;
}
.order_page .order_box_scroll .ordersName_price .priceBox {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  margin-left: 70px;
  max-width: 170px;
  position: relative;
}
.order_page .order_box_scroll .ordersName_price .priceBox p {
  color: #262626;
  font-family: "Space Grotesk", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
.order_page .order_main_content .order_main_box .total_price_box {
  align-items: center;

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 41px;
  justify-content: space-around;
  margin-top: 15px;
  position: relative;
  width: 100%;
}
.order_page .order_main_content .order_main_box .black_solid,
.blue_solid {
  border-style: solid;
  border-width: 1px 0;
  background: #fac665;
  border-color: #000;
}
.order_page .order_main_content .order_main_box .total_price_box .boxTotal {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: center;
  /* max-width: 535px; */
}
.order_page .total_price_box .boxTotal p {
  color: #000;
  font-family: "Space Grotesk", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-left: 20px;
}
.order_page .css-19kzrtu {
  padding-top: 24px;
}
.blue_solid {
  background: rgba(219, 207, 250, 0.81);
  border-color: #00a9ff;
}
.red_solid {
  background: #34b5ff;
  border-color: #a1dcf7;
  border-style: solid;
  border-width: 1px 0;
}
.order_page .order_right {
  display: flex;
  flex-direction: column;
  height: auto;
  margin-left: 30px;
  margin-top: 86px;
  width: 30%;
}
.order_page .order_right .order_info_box {
  align-items: center;
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 20px;
  width: 100%;
  margin-bottom: 30px;
}
.order_page .order_info_box .order_user_img {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}
.order_page .order_info_box .order_user_img .order_user_avatar {
  border-radius: 30px;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  height: 112px;
  width: 117px;
}
.order_page .order_info_box .order_user_icon_box {
  background: rgba(0, 0, 0, 0.33);
  border-radius: 37px;
  display: flex;
  height: 30px;
  padding: 5px;
  position: absolute;
  right: 0;
  top: 81%;
  width: 30px;
}
.order_page .order_user_icon_box .order_user_prof_img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.order_page .order_info_box .order_user_name {
  color: #08090d;
  font-family: "Space Grotesk", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-top: 10px;
}
.order_page .order_info_box .order_user_prof {
  color: #a1a1a1;
  font-family: "Space Grotesk", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
.order_page .order_info_box .order_user_address {
  display: flex;
  flex-direction: row;
  width: 90%;
}
.order_page .order_info_box .spec_address_txt {
  color: #616164;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-left: 5px;
}

.order_page .order_info_box .card_half_input,
.order_page .order_info_box .card_input {
  background: #f5f5f5;
  border: 0.5px solid #b3a6a6;
  border-radius: 2px;
  color: #434141;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 40px;
  letter-spacing: -0.02em;
  line-height: 22px;
  margin-top: 10px;
  padding: 10px;
  width: 100%;
}
.order_page .order_info_box .card_half_input {
  width: 48%;
}
.order_page .order_info_box .card_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;
  width: 80%;
}
