*{
    font-family: "Space Grotesk", sans-serif;

}
.chatting .chat_frame {
    height: 500px;
    flex-direction: column;
    background: rgba(195, 243, 237, 0.774) ;

    border-radius: 10px;
    position: fixed;
    bottom: 180px;
    right: -380px;
    width: 380px;
    z-index: 9999;
    font-family: "Space Grotesk", sans-serif;

    transition: right 0.7s ease-in-out;
  }
  
  .chatting .chat_frame.open {
    right: 0;
    display: block;
    font-family: "Space Grotesk", sans-serif;

  }
  
  .chatting .chat_button {
    position: fixed;
    bottom: 110px;
    right: 30px;
    height: 60px;
    padding: 10px;
    border: none;
    border-radius: 20px;
    background-color: #00c9ae;
    color: white;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s;
    z-index: 999;
  }
  
  .chatting .chat_button:hover {
    bottom: 110px;
    cursor: pointer;
    background-color: #00c9ae;
  }
  
  .chatting .community_all_frame {
    width: 68%;
  
    height: auto;
    background: #00c9ae;
    border: 1px solid #ffffff;
    border-radius: 34px;
  }
  
  .chatting .chat_frame .chat_top {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(228, 228, 228, 0.83);
    font-family: "Space Grotesk", sans-serif;
    font-style: normal;
    font-weight: 770;
    font-size: 36px;
    background-color: #00c9ae;
    border-radius: 18px 18px 0 0;
    line-height: 54px;
    color: #fcf8f8;
  }
  
  .chatting .chat_content {
    position: relative;
    padding: 17px;
    width: 100%;
    height: 370px;
    display: flex;
    /* border: 1px solid red; */
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    /* overflow-y: scroll; */
  }
  
  .chatting .chat_content .chat_main {
    position: relative;
    /*padding: 17px;*/
    width: 100%;
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    /* overflow-y: scroll; */
  }
  
  .chatting .chat_frame .chat_bott {
    width: 100%;
    height: 64px;
    display: flex;
  
    justify-content: center;
    align-items: center;
    border-top: 1px solid #301579b5;
    font-family: "Space Grotesk", sans-serif;
    letter-spacing: 1px;
  }
  
  .chatting .chat_bott .msg_input {
    padding: 20px;
    width: 276px;
    height: 50px;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border-radius: 28px;
    border: none;
    outline-color: #26f8dc;
  
    font-family: "Space Grotesk", sans-serif;
    letter-spacing: 1px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  
  .chatting .chat_bott .send_msg_btn {
    margin-left: 14px;
    width: 50px;
    height: 50px;
  
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #00c9ae;
    border: none;
  }
  
  .chatting .chat_main .msg_left {
    margin-left: 10px;
    padding: 9px;
  
    min-width: 50px;
    width: auto;
    max-width: 250px;
    height: auto;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    background: #55a172;
    border-radius: 20px 20px 20px 0px;
    font-family: "Space Grotesk", sans-serif;
    font-size: 18px;
    color: #ffffff;
    font-weight: 660;
    letter-spacing: 1px;
  }
  
  .chatting .msg_right {
    padding: 9px;
    min-width: 50px;
    width: auto;
    max-width: 250px;
  
    height: auto;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    background: #00c9ae;
    border-radius: 20px 20px 0px 20px;
    font-family: "Space Grotesk", sans-serif;
    font-size: 18px;
    color: #ffffff;
    font-weight: 550;
    letter-spacing: 1px;
  }