@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");

.footer_section {
  width: 100%;
  height: 540px;
  display: flex;
  flex-direction: column;
  color: aliceblue;
  font-family: "Space Grotesk", sans-serif;
  background: url("/public/imagesfurnis/footer.svg");
  background-repeat: no-repeat;
  background-size: cover;

}
 .hover a{
    color: #edf0f8;
    font-weight: 600;
    font-size: larger;
}
.newsletter {
  color: rgb(20, 23, 24);
  font-size: 60px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.4px;
  text-align: center;
  font-family: "Space Grotesk", sans-serif;
  margin-top: 100px;
}
.deals {
  color: rgb(20, 23, 24);
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 20px;
}

#filled-basic{
    width: 396px;
    height: 28px;
    margin-left: 10px;
}

.signup{
    font-family: "Space Grotesk", sans-serif;
    text-decoration: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    background:none;
    
}

.footer_down{
    width:100% ;
    height: 249px;
    background-color: rgb(20, 23, 24);
    background-size: cover;
    margin-top: 28px;
    display: flex;
    
    color: aliceblue;


}

.line{
    width: 1px;
    height: 24px;
    position: static;
    background: rgb(108, 114, 117);
    margin: 10px;
    
}
.line1{
    width: 1040px;
    height: 1px;
    background: rgb(107, 115, 119);
    margin: 25px;
    align-items: center;
    display: flex;

    
}



.footer_links{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 980px;
    height: 46px;
}    

a{
    text-decoration: none;
    color: aliceblue;

}
.last_stroke{
    margin-left: 30px;
    
    align-items: center;
    width: 980px;
    height: 46px;    
}